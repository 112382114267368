import { Navigate, Outlet } from 'react-router-dom';
import { useAuthStore } from '../store/zustand';

const ProtectedRoute = () => {
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);

  if (isLoggedIn === undefined) {
    return null;
  }

  return isLoggedIn ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
