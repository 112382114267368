import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Login from './routes/Login';
import PublicRoute from './routes/PublicRoute';
import Register from './routes/Register';
import { useThemeMode } from './hooks/useThemeMode';
import { Toaster } from 'react-hot-toast';
import ForgotPassword from './routes/ForgotPassword';
import ResendVerification from './routes/ResendVerification';
import './i18n/config';
import Logout from './routes/Logout';
import AccountVerification from './routes/AccountVerification';
import ProtectedRoute from './routes/ProtectedRoute';
import Account from './routes/Account';
import ResetPassword from './routes/ResetPassword';
import Orders from './routes/Orders';
import PurchasedProducts from './routes/PurchasedProducts';
import GameLibrary from './routes/GameLibrary';
import DiscoverUsers from './routes/DisoverUsers';
import UserProfile from './routes/UserProfile';
import ReceivedFriendRequests from './routes/ReceivedFriendRequests';
import RequestedFriendRequests from './routes/RequestedFriendRequests';
import AcceptedFriendRequests from './routes/AcceptedFriendRequests';
import Chats from './routes/Chats';
import DiscoverWaypoints from './routes/DisoverWaypoints';
import JoinedWaypoints from './routes/JoinedWaypoints';
import Waypoint from './routes/Waypoint';

const App = () => {
  useThemeMode();

  return (
    <>
      <Helmet>
        <link href="/css/style.css" rel="stylesheet" />

        <script src="/js/uikit.min.js" type="text/javascript" />
        <script src="/js/simplebar.js" type="text/javascript" />

        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Router>
        <Routes>
          <Route element={<PublicRoute />}>
            <Route element={<Login />} path="/login" />
            <Route element={<Register />} path="/register" />
            <Route element={<ForgotPassword />} path="/forgot-password" />
            <Route
              element={<AccountVerification />}
              path="/account-verification"
            />
            <Route
              element={<ResendVerification />}
              path="/resend-verification"
            />
            <Route element={<ResetPassword />} path="/reset-password" />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route element={<Account />} path="/account" />
            <Route element={<GameLibrary />} path="/game-library" />
            <Route element={<Orders />} path="/account-orders" />
            <Route element={<PurchasedProducts />} path="/account-products" />
            <Route element={<DiscoverUsers />} path="/user/search" />
            <Route element={<UserProfile />} path="/user/:userId" />
            <Route
              element={<ReceivedFriendRequests />}
              path="/friend-requests/received"
            />
            <Route
              element={<RequestedFriendRequests />}
              path="/friend-requests/requested"
            />
            <Route
              element={<AcceptedFriendRequests />}
              path="/friend-requests/accepted"
            />
            <Route element={<Chats />} path="/chats" />
            <Route element={<DiscoverWaypoints />} path="/waypoint/search" />
            <Route element={<JoinedWaypoints />} path="/waypoints" />
            <Route
              element={<Waypoint />}
              path="/waypoint/:waypointId/:waypointName"
            />
          </Route>
          <Route element={<Logout />} path="/logout" />

          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      </Router>

      <Toaster position={'top-right'} />
    </>
  );
};

export default App;
